<template>
  <v-menu
    v-model="isOpen"
    :close-on-content-click="false"
    min-width="290"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        :value="value"
        :label="label"
        :error-messages="errorMessages"
        :hide-details="hideDetails"
        prepend-icon="mdi-calendar"
        clearable
        @input="onTextInput"
        @blur="onBlur"
      />
    </template>
    <v-date-picker
      :value="value"
      :locale="locale"
      first-day-of-week="1"
      no-title
      scrollable
      @input="onInput"
    />
  </v-menu>
</template>

<script>
import { format, isValid } from 'date-fns';
import i18n from '../../i18n/i18n-config';

export default {
  name: 'BaseDatepickerInput',

  props: {
    value: {
      type: String,
      default: '',
    },

    label: {
      type: String,
      default: i18n.t('date'),
    },

    errorMessages: {
      type: Array,
      default: () => [],
    },

    hideDetails: Boolean,
  },

  data() {
    return {
      isTyping: false,
      isOpen: false,
      timerID: null,
    };
  },

  computed: {
    locale() {
      return this.$store.state.settings.locale;
    },
  },

  methods: {
    onBlur(evt) {
      if (!this.isTyping || evt.target.value === this.value) {
        return;
      }
      if (!evt.target.value) {
        this.onInput('');
        return;
      }
      const date = new Date(evt.target.value);
      if (isValid(date)) {
        console.log(date);
        this.onInput(format(date, 'yyyy-MM-dd'));
      } else {
        this.onInput('');
      }
    },

    onInput(val) {
      this.isTyping = false;
      this.$emit('input', val);
      this.isOpen = false;
    },

    onTextInput(val) {
      if (!val) {
        this.$emit('input', '');
        return;
      }
      this.isTyping = true;
      const date = new Date(val);
      if (isValid(date)) {
        this.$emit('input', format(date, 'yyyy-MM-dd'));
      }
    },
  },
};
</script>

<style scoped>

</style>
